import { useEffect, useState } from 'react';
import { getUrlParams, parseUrlHash } from './utils';

const oktaBaseURL = 'https://flexerasoftware.okta.com';
const oktaClientId = '0oam4qx37d74Ksjbr0x7';
const LocalStorageRedirectUrl = 'redirect_url';

function doRedirect(url: string, token: string) {
	window.location.href = `/.netlify/functions/handle-login-redirect?site=${url}&token=${token}`;
}

export function useOktaSignIn() {
	const [loggedIn, setLoggedIn] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const urlParams = getUrlParams();
		if (urlParams.site) {
			localStorage.setItem(LocalStorageRedirectUrl, urlParams.site);
		}

		// init okta widget
		const oktaSignIn = new window.OktaSignIn({
			baseUrl: oktaBaseURL,
			oktaClientId: oktaClientId,
			redirectUri: window.location.origin,
			authParams: {
				issuer: oktaBaseURL + '/oauth2/default',
				responseType: ['id_token'],
				display: 'page'
			},
			features: {
				registration: false,
				autoPush: true,
				multiOptionalFactorEnroll: true
			}
		});

		oktaSignIn.session.get(async (res: any) => {
			if (res.status === 'INACTIVE') {
				setLoading(false);
			}
			// Session exists, show logged in state.
			if (res.status === 'ACTIVE') {
				const response = await fetch('/.netlify/functions/verify-okta-session', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					credentials: 'same-origin',
					body: JSON.stringify({
						okta_id: res.id
					})
				});

				const data = await response.json();

				setLoading(false);
				setLoggedIn(true);

				if (data.error === 'MFA_REQUIRED') {
					// first login, force re-login
					oktaSignIn.session.close(() => {
						alert('Relogin please');
						window.location.href = window.location.origin;
					});
				}

				const redirectUrl = localStorage.getItem(LocalStorageRedirectUrl);
				const hashData = parseUrlHash();

				// if there is a redirect site do the redirect
				if (urlParams.site || hashData.id_token) {
					if (redirectUrl) {
						doRedirect(redirectUrl, data.token);
					}
				}
			}
			// No session, show the login form
			oktaSignIn.renderEl({ el: '#okta-login-container' },
				(resp: any) => resp.session.setCookieAndRedirect(document.location.href),
				// tslint:disable-next-line:no-console
				(err: any) => console.error(err)
			);
		});
	}, []);

	return { loggedIn, loading };
}
