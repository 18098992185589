import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	html,
	body {
		margin: 0;
		font-family: Nunito;
		overflow-y: hidden;

		h1, h2, h3, h4 {
			margin-top: 0;
		}
	}

	body,
	html,
	#root {
		margin: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
`;
