import { BaseTheme, Cell, Flex, Grid, Header, HeaderBrand, HeaderDivider, HeaderProduct } from '@flexera/ui-react-components';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader/root';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './GlobalStyles';
import { useOktaSignIn } from './oktaSignIn';

export const App = () => {
	const { loggedIn, loading } = useOktaSignIn();
	// const loggedIn = false;
	// const loading = true;

	let content: ReactNode;
	if (loading) {
		content = <Flex isContentCentered>
			<h1>Please wait...</h1>
		</Flex>;
	}
	else if (loggedIn) {
		content = <Flex isContentCentered>
			<h1>Logged In!</h1>
		</Flex>;
	}
	else {
		content = <Flex isContentCentered>
			<div id="okta-login-container" />
		</Flex>;
	}

	return <ThemeProvider theme={BaseTheme}>
		<>
			<GlobalStyles />
			<Grid rows="70px 1fr" columns="auto" height="100%">
				<Cell>
					<Header id="header">
						<HeaderBrand id="logo" title="logo" />
						<HeaderDivider />
						<HeaderProduct>Engineering Documentation</HeaderProduct>
					</Header>
				</Cell>
				<Cell>
					{content}
				</Cell>
			</Grid>
		</>
	</ThemeProvider>;
};

export const AppRoot = hot(App);
